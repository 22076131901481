import React, { useState } from 'react';
import Select from 'react-select';

const SettingsPage = () => {
  const [selectedCurrencies, setSelectedCurrencies] = useState([]);
  const [responseData, setResponseData] = useState({});
  const [sheetName, setSheetName] = useState('');
  const [loading, setLoading] = useState(false);
  const [generateMessage, setGenerateMessage] = useState(null);
  const [error, setError] = useState(null);

  const currencyOptions = [
    { value: 'litecoin', label: 'Litecoin' },
    { value: 'dogecoin', label: 'Dogecoin' },
    { value: 'bitcoin', label: 'Bitcoin' },
  ];

  const handleFetchSubmit = async () => {
    if (selectedCurrencies.length === 0) {
      setError("Please select at least one currency");
      return;
    }

    const currencies = selectedCurrencies.map((currency) => currency.value).join(',');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/mappings?currencies=${currencies}`);
      const data = await response.json();
      setResponseData(data);
      setError(null);
    } catch (err) {
      console.error("Error fetching data:", err);
      setError("Failed to fetch data. Please try again.");
    }
  };

  const handleGenerateSubmit = async () => {
    if (selectedCurrencies.length === 0) {
      setGenerateMessage({ type: 'error', text: 'Please select at least one currency.' });
      return;
    }
    if (!sheetName.trim()) {
      setGenerateMessage({ type: 'error', text: 'Sheet name cannot be empty.' });
      return;
    }

    setLoading(true);
    setGenerateMessage(null);

    const requestBody = {
      currencies: selectedCurrencies.map((currency) => currency.value),
      sheet_name: sheetName.trim(),
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      const data = await response.json();
      setLoading(false);

      if (data.status === 200) {
        setGenerateMessage({ type: 'success', text: 'Report generated successfully!' });
      } else {
        setGenerateMessage({ type: 'error', text: `Error: ${data.error}` });
      }
    } catch (err) {
      setLoading(false);
      console.error("Error generating report:", err);
      setGenerateMessage({ type: 'error', text: 'Failed to generate report. Please try again.' });
    }
  };

  return (
    <div className="settings-page">
      <h1>Settings Page</h1>

      {/* Multiselect Dropdown and Fetch Button */}
      <div className="filter-container">
        <Select
          isMulti
          options={currencyOptions}
          value={selectedCurrencies}
          onChange={setSelectedCurrencies}
          placeholder="Select Currencies"
          className="multi-select"
          classNamePrefix="select"
        />
        <button onClick={handleFetchSubmit}>Fetch Data</button>
      </div>

      {error && <div className="error-message">{error}</div>}

      {/* Metadata Section */}
      {Object.keys(responseData).length > 0 && (
        <div>
          <div className="metadata">
            <h2>Metadata</h2>
            {Object.entries(responseData).map(([currency, data]) => (
              <div key={currency}>
                <h3>{currency.charAt(0).toUpperCase() + currency.slice(1)}</h3>
                <ul>
                  {data.metadata.map((item, index) => (
                    <li key={index}>
                      <strong>{item.key}:</strong> {item.value}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>

          {/* Generate New Section */}
          <div className="generate-new">
            <h2>Generate New</h2>
            <input
              type="text"
              placeholder="Enter Sheet Name"
              value={sheetName}
              onChange={(e) => setSheetName(e.target.value)}
              className="generate-input"
            />
            <button onClick={handleGenerateSubmit} disabled={loading}>
              {loading ? 'Generating...' : 'Generate'}
            </button>
            {generateMessage && (
              <div className={`generate-message ${generateMessage.type}`}>
                {generateMessage.text}
              </div>
            )}
          </div>

          {/* Cell Mappings Section */}
          <div className="cell-mappings">
            <h2>Cell Mappings</h2>
            <table className="data-table">
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Cell</th>
                  <th>Accounts</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(responseData).flatMap(([currency, data]) =>
                  data.cell_mappings.map((mapping, index) => (
                    <tr key={`${currency}-${index}`}>
                      <td>{currency}</td>
                      <td>{mapping.cell}</td>
                      <td>
                        {mapping.accounts.map((account, idx) => (
                          <span key={idx} className="account-tag">
                            {account}
                          </span>
                        ))}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsPage;
