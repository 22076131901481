import React from 'react';

const BillingFormulaTable = ({ data }) => {

  return (
    <table className="financial-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Account</th>
          <th>Currency</th>
          <th>Date</th>
          <th>Average Hash Rate</th>
          <th>Theoretical Hash Rate</th>
          <th>Revenue</th>
          <th>Revshare %</th>
          <th>Energy Rate</th>
          <th>Energy Cost</th>
          <th>Profit Share</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.account}</td>
            <td>{item.currency}</td>
            <td>{item.date}</td>
            <td>{item.avg_hash_rate} GH/s</td>
            <td>{item.theoretical_hash_rate} GH/s</td>
            <td>{item.revenue}</td>
            <td>{item.revshare}</td>
            <td>{item.energy_rate}</td>
            <td>{item.energy_cost}</td>
            <td>{item.profit_share}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default BillingFormulaTable;
