import React, { useEffect, useState } from 'react';

const ClientsPage = () => {
  const [clientsData, setClientsData] = useState([]);
  const [filteredClientsData, setFilteredClientsData] = useState([]);
  const [filters, setFilters] = useState({});

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/clients`);
        const data = await response.json();
        setClientsData(data);
        setFilteredClientsData(data);
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchClients();
  }, []);

  const handleFilterChange = (column, value) => {
    const newFilters = { ...filters, [column]: value };
    setFilters(newFilters);

    const filteredData = clientsData.filter((client) =>
      Object.keys(newFilters).every((key) =>
        client[key].toString().toLowerCase().includes(newFilters[key].toLowerCase())
      )
    );

    setFilteredClientsData(filteredData);
  };

  return (
    <div className="clients-page">
      <h1>Clients</h1>
      <table className="data-table">
        <thead>
          <tr>
            {clientsData.length > 0 &&
              Object.keys(clientsData[0]).map((column) => (
                <th key={column}>
                  {column}
                  <input
                    type="text"
                    placeholder={`Filter ${column}`}
                    value={filters[column] || ''}
                    onChange={(e) => handleFilterChange(column, e.target.value)}
                    style={{ marginTop: '5px', width: '90%', fontSize: '14px', padding: '4px' }}
                  />
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {filteredClientsData.length > 0 ? (
            filteredClientsData.map((client, index) => (
              <tr key={index}>
                {Object.values(client).map((value, i) => (
                  <td key={i}>{value}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={Object.keys(clientsData[0] || {}).length} className="no-records-message">
                No records found with given filters
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ClientsPage;
