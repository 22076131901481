import React, { useState, useEffect } from 'react';
import Sidebar from './components/Sidebar';
import { useAuth0 } from '@auth0/auth0-react';
import LoginButton from './components/LoginButton';
import LogoutButton from './components/LogoutButton';
import RevenuePage from './pages/RevenuePage';
import HashRatePage from './pages/HashRatePage';
import ClientsPage from './pages/ClientsPage';
import SettingsPage from './pages/SettingsPage';
import BillingFormulaPage from './pages/BillingFormulaPage';
import './App.css';

function App() {
  const [activeTab, setActiveTab] = useState('revenue');
  const { isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0();
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkSession = async () => {
      try {
        await getAccessTokenSilently();
      } catch (error) {
        console.log("Session check failed:", error);
      }
    };

    if (isAuthenticated) {
      checkSession();
      const allowedDomains = ['alteri.io'];
      const userDomain = user.email.split('@')[1];
      if (!allowedDomains.includes(userDomain)) {
        setError('Access restricted to company users only. Please use your company email.');
        logout({ returnTo: window.location.origin });
      }
    }
  }, [isAuthenticated, getAccessTokenSilently, user, logout]);

  const renderContent = () => {
    switch (activeTab) {
      case 'revenue':
        return <RevenuePage />;
      case 'hash_rate':
        return <HashRatePage />;
      case 'billing_formula':
        return <BillingFormulaPage />;
      case 'clients':
        return <ClientsPage />;
      case 'settings':
        return <SettingsPage />;
      default:
        return <RevenuePage />;
    }
  };

  return (
    <div className="App">
      {!isAuthenticated ? (
        <LoginButton />
      ) : (
        <>
          {error && <div className="error-message">{error}</div>}
          <h2>Welcome, {user.name}</h2>
          <LogoutButton />
          <div className="dashboard">
            <Sidebar onTabSelect={setActiveTab} />
            <div className="content">
              {renderContent()}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
