import React from 'react';

const Sidebar = ({ onTabSelect }) => {
  return (
    <div className="sidebar">
      <button onClick={() => onTabSelect('revenue')}>Revenue</button>
      <button onClick={() => onTabSelect('hash_rate')}>Hash Rate</button>
      <button onClick={() => onTabSelect('billing_formula')}>Billing Formula</button>
      <button onClick={() => onTabSelect('clients')}>Clients</button>
      { /* <button onClick={() => onTabSelect('settings')}>Settings</button> */}
    </div>
  );
};

export default Sidebar;
