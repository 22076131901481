import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LoginButton.css';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="login-container">
      <h1 className="login-title">Welcome to Alteri Billing Dashboard</h1>
      <p className="login-subtitle">Please log in with your company account to continue</p>
      <button className="login-button" onClick={() => loginWithRedirect()}>
        Log in with Google
      </button>
    </div>
  );
};

export default LoginButton;
