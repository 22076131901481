import React from 'react';

const FinancialTable = ({ data }) => {
  // Calculate the total amount
  const totalAmount = data.reduce((sum, item) => sum + item.amount, 0);

  return (
    <table className="financial-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Location</th>
          <th>Account</th>
          <th>Currency</th>
          <th>Amount</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            <td>{item.id}</td>
            <td>{item.location}</td>
            <td>{item.mining_account}</td>
            <td>{item.currency}</td>
            <td>{item.amount}</td>
            <td>{item.created_at}</td>
          </tr>
        ))}
        <tr>
          <td colSpan="4"><strong>Total</strong></td>
          <td><strong>{totalAmount.toFixed(2)}</strong></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

export default FinancialTable;
