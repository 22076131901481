import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import FinancialTable from '../components/FinancialTable';

const RevenuePage = () => {
  const [filteredData, setFilteredData] = useState([]);
  const [category, setCategory] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [accounts, setAccounts] = useState([]);
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/accounts`);
        const accountsData = await response.json();
        const accountOptions = accountsData.map((account) => ({
          value: account.name,
          label: account.name,
        }));
        setAccounts(accountOptions);
      } catch (error) {
        console.error('Error fetching accounts:', error);
      }
    };

    fetchAccounts();
  }, []);

  const handleSubmit = async () => {
    const queryParams = new URLSearchParams({
      startDate,
      endDate,
      currency: category,
      accounts: selectedAccounts.join(','),
    });

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/v1/revenue?${queryParams}`);
      const data = await response.json();
      setFilteredData(data);
      setIsDataFetched(true); // Mark that data has been fetched to show message if empty
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
    <div>
      <h1>Revenue Page</h1>
      <div className="filter-container">
        <select value={category} onChange={(e) => setCategory(e.target.value)}>
          <option value="">Select Currency</option>
          <option value="litecoin">Litecoin</option>
          <option value="dogecoin">Dogecoin</option>
          <option value="bitcoin">Bitcoin</option>
        </select>

        <input
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <input
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />

        <Select
          options={accounts}
          isMulti
          onChange={(selectedOptions) => setSelectedAccounts(selectedOptions.map((option) => option.value))}
          value={accounts.filter((account) => selectedAccounts.includes(account.value))}
          placeholder="Select Accounts"
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          className="account-multiselect"
          styles={{
            option: (provided, state) => ({
              ...provided,
              display: 'flex',
              alignItems: 'center',
              color: state.isSelected ? '#000' : '#555',
              backgroundColor: state.isFocused ? '#e2e2e2' : 'white',
            }),
            multiValue: (provided) => ({
              ...provided,
              backgroundColor: '#f0f0f0',
            }),
          }}
        />

        <button onClick={handleSubmit}>Submit</button>
      </div>

      {isDataFetched && (
        filteredData.length > 0 ? (
          <FinancialTable data={filteredData} />
        ) : (
          <div className="no-records-message">
            No records found with given filters
          </div>
        )
      )}
    </div>
  );
};

export default RevenuePage;
