import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import './LogoutButton.css';

const LogoutButton = () => {
  const { logout } = useAuth0();
  const returnToDomain = process.env.LOGOUT_URL;

  return (
    <button className="logout-button" onClick={() => logout({ returnTo: returnToDomain })}>
      Log Out
    </button>
  );
};

export default LogoutButton;
